import { Component, Input, input } from "@angular/core";

@Component({
  selector: "app-area-card",

  templateUrl: "./area-card.component.html",
  styleUrl: "./area-card.component.scss",
})
export class AreaCardComponent {
  @Input() title: string = "";
  @Input() description: string = "";
  @Input() url: string = "";
}
