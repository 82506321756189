import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ORDER_TYPE } from "../data/constants";

@Injectable({
  providedIn: "root",
})
export class BaseDataService {
  private baseUrl: string = environment.baseUrl; // Replace with your actual base URL
  private cache = new Map<string, any>();
  public APPCODE: string = environment.appCode;

  constructor(private http: HttpClient) {}

  // Method to create headers
  private createHeaders(includeVersion=false): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");

    if (includeVersion) {
      headers = headers.set("DeviceType", "IOS");
      headers = headers.set("AppVersion", "3.0.0");
    }
    // headers = headers.set("lang", "EN");
   
    // const token = localStorage.getItem('token');
    // if (token) {
    //   headers = headers.set('Authorization', `Bearer ${token}`);
    // }
    return headers;
  }

  // Method to build the full URL
  private buildUrl(endpoint: string,trimApi=false): string {
var baseUrl=this.baseUrl;
    if (trimApi) {
      if (baseUrl.includes('api/')) {
        baseUrl=baseUrl.split('api/')[0]
      }
    }
    return `${baseUrl}${endpoint}`;
  }

  // Generic GET method
  public get<T>(endpoint: string, cache: boolean = false,includeVersion=false): Observable<T> {
    const url = this.buildUrl(endpoint);
    if (cache && this.cache.has(url)) {
      return of(this.cache.get(url));
    }

    const headers = this.createHeaders(includeVersion);
    return this.http.get<T>(url, { headers }).pipe(
      tap((data) => {
        if (cache) {
          this.cache.set(url, data);
        }
      }),
      catchError(this.handleError)
    );
  }

  // Generic POST method
  public post<T>(endpoint: string, data: any,trimApi=false): Observable<T> {
    const url = this.buildUrl(endpoint,trimApi);
    const headers = this.createHeaders();
    return this.http
      .post<T>(url, data, { headers })
      .pipe(catchError(this.handleError));
  }

  // Generic PUT method
  public put<T>(endpoint: string, data: any): Observable<T> {
    const url = this.buildUrl(endpoint);
    const headers = this.createHeaders();
    return this.http
      .put<T>(url, data, { headers })
      .pipe(catchError(this.handleError));
  }

  // Generic DELETE method
  public delete<T>(endpoint: string): Observable<T> {
    const url = this.buildUrl(endpoint);
    const headers = this.createHeaders();
    return this.http
      .delete<T>(url, { headers })
      .pipe(catchError(this.handleError));
  }

  // File download method
  public download(endpoint: string, params?: HttpParams): Observable<Blob> {
    const url = this.buildUrl(endpoint);
    const headers = this.createHeaders();
    return this.http
      .get(url, { headers, params, responseType: "blob" })
      .pipe(catchError(this.handleError));
  }

  // File upload method
  public upload<T>(endpoint: string, formData: FormData): Observable<T> {
    const url = this.buildUrl(endpoint);
    const headers = new HttpHeaders();
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return this.http
      .post<T>(url, formData, { headers })
      .pipe(catchError(this.handleError));
  }

  // Error handling
  private handleError(error: HttpErrorResponse) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  get isPickup(): boolean {
    return this.deliveryType == ORDER_TYPE.PICKUP;
  }

  get deliveryType(): string {
    return localStorage.getItem("orderType") ?? ORDER_TYPE.DELIVERY;
  }

  get isPickupParameter():string{
    return "isPickup="+this.isPickup
  }
}
