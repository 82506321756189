import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LocationSearchSharedService {
  private dataSource = new BehaviorSubject<any>(null);
  private locationSearchModalSubject = new BehaviorSubject<any>(null);

  constructor() {}

  setOpenLocactionModal(open: string) {
    this.locationSearchModalSubject.next(open);
  }

  getLocationModal() : Observable<any>{
    return this.locationSearchModalSubject.asObservable()
  }
}
