import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-filter-checks',
  standalone: false,

  templateUrl: './skeleton-filter-checks.component.html',
  styleUrl: './skeleton-filter-checks.component.scss'
})
export class SkeletonFilterChecksComponent {
  @Input() skeletonItems: any[] = Array(2);
}
