import { Component, ViewChild } from "@angular/core";
import { ReplaySubject, Subscription, takeUntil } from "rxjs";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ProductSharedService } from "../../services/product-shared.service";
import { SectorStoreService } from "src/app/home/sectors/services/sector-store.service";
import {
  MenuItemOne,
  MenuItemReq,
} from "src/app/home/sectors/stores/models/menu-items";
@Component({
  selector: "app-product-modal",
  // standalone: true,
  // imports: [],
  templateUrl: "./product-modal.component.html",
  styleUrl: "./product-modal.component.scss",
})
export class ProductModalComponent {
  @ViewChild("template", { static: true }) template: any;
  productDataSubject: Subscription;
  modalRef: BsModalRef;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  isCollapsed: boolean = false;
  isCustomCollapsed: boolean = true;
  sizeList: string[] = ["Small", "Large"];
  customizeList: any[] = ["Fries", "Fries with extra cheese"];
  itemId: string = "";
  menuItemReq: MenuItemReq;
  menuItemOne: MenuItemOne;
  collapsedCustomizations: boolean[] = [];
  constructor(
    private productSharedService: ProductSharedService,
    private modalService: BsModalService,
    private sectorStoreService: SectorStoreService
  ) {
    this.productDataSubject = productSharedService
      .getSubjectOpenProductModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null) {
          this.menuItemReq = sortByOpen;
          this.getMenuItemById();

          this.showModal();
          this.isCollapsed = false;


        }
      });
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: "modal-dialog-centered modal-lg",
    });
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.onHide()
  }

  collapses() {}
  expands() {}
  collapsed() {}
  expanded() {}
  toggleCustomCollapse(index: number) {
    this.collapsedCustomizations[index] = !this.collapsedCustomizations[index];
  }
  ngOnDestroy(): void {
    this.productSharedService.setSubjectOpenProductModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }

  onHide(){
    this.modalService.onHide.pipe(takeUntil(this.destroy)).subscribe(res=>{
      this.menuItemOne=null
      this.menuItemReq=null

    })
  }

  getMenuItemById() {
    this.sectorStoreService
      .getMenuItemFindOne(this.menuItemReq.StoreId, this.menuItemReq.ItemId)
      .pipe(takeUntil(this.destroy))
      .subscribe((res) => {
        this.menuItemOne = res;
        this.collapsedCustomizations = Array(this.menuItemOne.Customizations.length).fill(false);
        this.collapsedCustomizations[0]=true;
      });
  }
  currencyFromat(price: number) {
    return price?.toFixed(3);
  }
}
