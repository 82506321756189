import { Component, Input } from "@angular/core";
import {
  Location,
  locationSearchResult,
  LocationSearchResult,
} from "src/app/shared/data/location-search";
import { LocationSearchItemComponent } from "../location-search-item/location-search-item.component";
import { LocationSearchSharedService } from "src/app/home/services/locations/location-search-shared.service";
import { GoogleMapService } from "src/app/shared/services/google-map-service.service";
import { ReplaySubject, takeUntil } from "rxjs";

@Component({
  selector: "app-location-search-list",
  standalone: false,

  templateUrl: "./location-search-list.component.html",
  styleUrl: "./location-search-list.component.scss",
})
export class LocationSearchListComponent {
  @Input() locations: Location[] = [];
  locationsList: Location[];
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private locationSharedService: LocationSearchSharedService,
    private mapService: GoogleMapService
  ) {
    // this.locationsList=locationSearchResult;
  }
  openLocationModal(open: string) {
    this.locationSharedService.setOpenLocactionModal(open);
  }

  selectLocation(location: Location) {
    // this.mapService
    // .searchLocation(location.text, this.map)
    // .pipe(takeUntil(this.destroy))
    // .subscribe((res) => {
    //   console.log(res);

    //   var coords = new google.maps.LatLng(res.latitude, res.longitude);
    //   this.currentLocation = res;
    //   this.currentLocation.text=location.text
    //   this.map.setZoom(18);
    //   this.map.setCenter(coords);
    //   this.marker.setPosition(coords);
    //   this.isFetching = false;
    //   this.locatiionSearchList = [];
    //   this.cdr.detectChanges();
    // });
    this.mapService
      .getCoordinates(location.text)
      .pipe(takeUntil(this.destroy))
      .subscribe((res) => {
        location.latitude = res.latitude;
        location.longitude = res.longitude;
        this.mapService.saveLocation(location);
        this.openLocationModal("open");
      });
  }
}
