import { Component } from '@angular/core';

@Component({
  selector: 'app-testimonial-card',
  standalone: false,

  templateUrl: './testimonial-card.component.html',
  styleUrl: './testimonial-card.component.scss'
})
export class TestimonialCardComponent {

}
