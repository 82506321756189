import { Component, OnInit, Input, HostListener } from "@angular/core";
import { GoogleMapService } from "../../services/google-map-service.service";
import { Location } from "../../data/location-search";
import { LocationSearchSharedService } from "src/app/home/services/locations/location-search-shared.service";
import { OrderService } from "../../services/order.service";

@Component({
  selector: "app-header-two",
  templateUrl: "./header-two.component.html",
  styleUrls: ["./header-two.component.scss"],
})
export class HeaderTwoComponent implements OnInit {
  @Input() class: string;
  @Input() themeLogo: string = "assets/images/tm/logo.png";
  Hamburgermenu: string = "assets/images/tm/icons/hamburger-menu.png";
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  searchtext:string=''
  public stick: boolean = false;
  location: Location;
  orderType: string = 'DELIVERY';
  DELIVERY_TYPE: string = 'DELIVERY';
  PICKUP_TYPE: string = 'PICKUP';

  constructor(
    private mapService: GoogleMapService,
    private locationSharedService: LocationSearchSharedService,
    private orderService:OrderService
  ) {
    mapService
      .getCurrentLocation()
      .pipe()
      .subscribe((res) => {
        this.searchtext=''
        console.log( res,'loc');
        if (res!=null) {
          this.location = this.mapService.getCurrentLocationValue();
          this.searchtext=this.location?.text ?? ''
        }else{
          this.searchtext=''
        }
      console.log( this.searchtext);
      
        
      });
  }
  ngOnInit(): void {
    this.location = this.mapService.getCurrentLocationValue();
    this.searchtext=this.location?.text ?? ''
    if(localStorage.getItem('orderType')) {
      this.orderType = localStorage.getItem('orderType');
    }
    this.orderService.setOrderTypeChanged(this.orderType)
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }
  openLocationModal(open: string) {
    this.locationSharedService.setOpenLocactionModal(open);
  }

  orderTypeClick(orType: string) {
    this.orderType = orType;
      localStorage.setItem('orderType', orType);
      this.orderService.setOrderTypeChanged(orType)
  }
  setSearchTextChanged(event){
    console.log(event);
    
    this.orderService.setSearchTextChanged(event.target.value)
  }
}
