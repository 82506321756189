import { Injectable } from "@angular/core";
import { BaseDataService } from "src/app/shared/services/base-data.service";
import { GoogleMapService } from "src/app/shared/services/google-map-service.service";
import {
  SectorStore,
  SectorStorePaginated,
  SectorStoresReq,
} from "../models/sector.model";
import { Observable } from "rxjs";
import {
  MenuItemOne,
  MenuItemVm,
  VendorType,
} from "../stores/models/menu-items";

@Injectable({
  providedIn: "root",
})
export class SectorStoreService {
  private sectorStoresApiUrl: string = "stores/web/list";
  private sectorStoresInfoApiUrl: string = "stores/web/info";
  private menuItemFindApiUrl: string = "menutem/find";
  private menuItemfindOneApiUrl: string = "menutem/findOne";

  constructor(
    private baseDataService: BaseDataService,
    private googleMapService: GoogleMapService
  ) {}

  getSectorStores(sectorStoresReq: SectorStoresReq) {
    // sectorStoresReq.Coordinates =
    // this.googleMapService.getCurrentLocationArray;
    sectorStoresReq.Coordinates =
    ( (sectorStoresReq?.ZoneSlug && sectorStoresReq?.ZoneSlug.length) > 0 ||
     (sectorStoresReq.AreaSlug && sectorStoresReq?.AreaSlug.length )> 0)
        ? []
        : this.googleMapService.getCurrentLocationArray;
    return this.baseDataService.post<SectorStorePaginated>(
      this.sectorStoresApiUrl,
      sectorStoresReq,
      false
    );
  }

  getStoreBySulg(slug: string): Observable<SectorStore> {
    var constructedUrl =
      this.sectorStoresInfoApiUrl +
      "?slug=" +
      encodeURIComponent(slug) +
      "&" +
      this.baseDataService.isPickupParameter +
      "&" +
      this.googleMapService.getLocationParameter();
    return this.baseDataService.post<SectorStore>(constructedUrl, {}, false);
  }

  getMenuItemsSummary(storeId: string): Observable<MenuItemVm> {
    return this.baseDataService.post<MenuItemVm>(
      this.menuItemFindApiUrl + "?storeId=" + storeId,
      {}
    );
  }
  getMenuItemFindOne(
    storeId: string,
    menuItemId: string
  ): Observable<MenuItemOne> {
    return this.baseDataService.post<MenuItemOne>(
      this.menuItemfindOneApiUrl +
        "?storeId=" +
        storeId +
        "&menuItemId=" +
        menuItemId,
      {}
    );
  }
}
