import { Component, Input } from "@angular/core";
import { SectorStore } from "src/app/home/sectors/models/sector.model";

@Component({
  selector: "app-store-tile-one",

  templateUrl: "./store-tile-one.component.html",
  styleUrl: "./store-tile-one.component.scss",
})
export class StoreTileOneComponent {
  @Input() storeData: SectorStore;

  joinCuisine(arr: string[]) {
    arr.join(" ");
  }

  getCoverUrl() {
    return this.storeData?.CoverUrl.length? this.storeData.CoverUrl : this.storeData?.LogoUrl
  }
}
