import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sector-card-two',

  templateUrl: './sector-card-two.component.html',
  styleUrl: './sector-card-two.component.scss'
})
export class SectorCardTwoComponent {
  @Input() title:string=""
  @Input() img:string=""
  @Input() active:boolean=false
}
