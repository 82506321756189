import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cuisine } from '../data/cuisine';
import { BaseDataService } from './base-data.service';

@Injectable({
  providedIn: 'root'
})
export class CuisineService {
  private cuisinesApiUrl: string = "/cuisines/all";
  constructor(private baseDataService:BaseDataService) { }

getAllCuisine():Observable<Cuisine[]>{
  return this.baseDataService.get<Cuisine[]>(this.cuisinesApiUrl)
}

}
