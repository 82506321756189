import { Component,Input } from '@angular/core';
import { SectorBaseModel } from 'src/app/home/sectors/models/sector.model';

@Component({
  selector: 'app-sector-tile-one',

  templateUrl: './sector-tile-one.component.html',
  styleUrl: './sector-tile-one.component.scss'
})
export class SectorTileOneComponent {
@Input() sectorData: SectorBaseModel=null;
@Input() selectedSector: string

ngOnInit(): void {
  //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
  //Add 'implements OnInit' to the class.
  console.log(this.selectedSector,"selectedSector22");
  
}
}
