<div class="area-card p-3 mt-5">
    <div class="row">
        <div class="col-10">
            <h3 class="title fs-6">
                {{title}}
            </h3>
            <p class=" description fs-6 text-white">
                {{description}}
            </p>
        </div>
        <div class="col-2">
            <div class="btn-next">

                <a href="/{{url}}" >
                    <img src="assets/images/tm/icons/next-icon.svg" alt="" class="img-fluid">
                </a>
            </div>
        </div>
    </div>
</div>
