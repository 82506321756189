<!-- <div class="location-item">
    <div class="location-details">
      <p class="location-name">{{location.street}}, {{location.city}}, {{location.country}}</p>
      <p class="additional-info">{{location.additionalInfo}}</p>
    </div>
  </div> -->


<div class="row mt-2">
    <div class="col-md-12">
        <div class="card location-item-card">

          <div class=" p-2 ">
            <h6 class="f-weight-400 p-0 m-0">{{location.primaryText}}</h6>
            <p class="p-0 m-0">{{location.text}}</p>
          </div>

        </div>
    </div>
</div>