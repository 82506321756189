<!-- <nav class="navbar navbar-expand-lg navbar-light bg-white border">
    <div class="container">
        <a class="" href="#">
            <img [src]="Hamburgermenu" alt="">
        </a>
        <a class="navbar-brand" href="#">
            <img [src]="themeLogo" alt="Logo">
        </a>

        <div class="p-2 d-flex  align-content-center location-search-input align-items-center">
            <img src="assets/images/tm/icons/location-search-icn.svg" class="mr-2" alt="pin location">
            <input type="text" placeholder="Search for area, street name, landmark…" class="w-100 f-16 f-m-14 pe-1 ps-3"
                autocomplete="off">


        </div>
        <div class="p-2 d-flex align-content-center location-search-input align-items-center">
            <img src="assets/images/tm/icons/location-search-icn.svg" class="mr-2" alt="pin location">
            <input type="text" placeholder="Search for area, street name, landmark…" class="w-100 f-16 f-m-14 pe-1 ps-3" value="{{location.primaryText}}"
                autocomplete="off" [readOnly]="true">

                <img src="assets/images/tm/icons/locaction-marker-icn.svg" class="clickable" alt="clear selected location" >
        </div>
        <div class="collapse navbar-collapse d-none align-content-center d-lg-flex" id="navbarDeliveryPickup">
            <div class="btn-group custom-btn-group" role="group" aria-label="Delivery and Pickup">
                <button type="button" class="btn btn-delivery ">Delivery</button>
                <button type="button" class="btn btn-pickup active">Pickup</button>
            </div>
        </div>

   
    </div>
</nav> -->

<nav class="navbar navbar-expand-lg navbar-light bg-white border">
    <div class="container d-flex justify-content-between align-items-center">
        <!-- Left Section: Hamburger Menu and Logo -->
        <div class="d-flex align-items-center">
            <a class="me-3 hamburgerIcon" href="#">
                <img [src]="Hamburgermenu" alt="Hamburger Menu">
            </a>
            <a class="navbar-brand" href="#">
                <img [src]="themeLogo" alt="Logo">
            </a>
        </div>

        <!-- Middle Section: Search Bars with Increased Width -->
        <div class="d-flex flex-grow-1 justify-content-center align-items-center">
            <div class="p-2 d-flex align-content-center location-search-input align-items-center me-2 flex-grow-1" >
                <img src="assets/images/tm/icons/Ic_home_search.png" class="me-2" alt="pin location">
                <input type="text" placeholder="Search restaurants, stores, items, Etc." class="w-100 f-16 f-m-14 pe-1 ps-3"
                    autocomplete="off" (input)="setSearchTextChanged($event)">
            </div>
            <div class="p-2 d-flex align-content-center location-search-input align-items-center ms-2 clickable">
                <img src="assets/images/tm/icons/location-search-icn.svg" class="me-2" alt="pin location">
                <input type="text" placeholder="" class="w-100 f-16 f-m-14 pe-1 ps-3 clickable" [(ngModel)]="searchtext"
                    autocomplete="off" [readOnly]="true" (click)="openLocationModal('open')">
                <img src="assets/images/tm/icons/input-arrow-down.png" class="ms-2 clickable" alt="clear selected location">
            </div>
        </div>

        <!-- Right Section: Delivery/Pickup Buttons and Cart -->
        <div class="d-flex align-items-center p-2">
            <div class="btn-group custom-btn-group me-3  ms-2" role="group" aria-label="Delivery and Pickup">
                <button type="button" class="btn btn-delivery" [class]="orderType == DELIVERY_TYPE ? 'active': '' " (click)="orderTypeClick(DELIVERY_TYPE)">Delivery</button>
                <button type="button" class="btn btn-pickup" [class]="orderType == PICKUP_TYPE ? 'active': '' " (click)="orderTypeClick(PICKUP_TYPE)">Pickup</button>
            </div>
            <!-- <a href="#" class="position-relative">
                <i class="bi bi-cart-fill" style="font-size: 24px;"></i>
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    0
                    <span class="visually-hidden">unread messages</span>
                </span>
            </a> -->
        </div>
    </div>
</nav>
