import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-sector-card',

  templateUrl: './sector-card.component.html',
  styleUrl: './sector-card.component.scss'
})
export class SectorCardComponent {
@Input() title:string=""
@Input() img:string=""
@Input() active:boolean=false
}
