import { Component, Input, input } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ReplaySubject, takeUntil } from "rxjs";
import { AttributeService } from "../../services/attributes.service";
import { Attribute, SortAttributes } from "../../data/sort-attributes.model";
import { CuisineService } from "../../services/cuisine.service";
import { Cuisine } from "../../data/cuisine";
import { SECTOR_SLUGS } from "../../data/constants";
import { SectorService } from "src/app/home/sectors/services/sector.service";
import { SectorBaseModel } from "src/app/home/sectors/models/sector.model";
import {
  MenuCategory,
  VendorType,
} from "src/app/home/sectors/stores/models/menu-items";
import { OrderService } from "../../services/order.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-common-filter",
  // standalone: true,
  // imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: "./common-filter.component.html",
  styleUrl: "./common-filter.component.scss",
})
export class CommonFilterComponent {
  @Input() filterForm: FormGroup = {} as FormGroup;
  @Input() selectedSector: string = "";
  @Input() filterType: string = "";
  @Input() menuCategories: MenuCategory[] = [];

  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  sortAttributesList: SortAttributes[] = [];
  attributesList: Attribute[] = [];
  isAttrLoading = false;
  isVendorTypeLoading = false;
  isSortAttrLoading = false;
  isCuisinesLoading = false;
  cuisinesList: Cuisine[] = [];
  sectorSlugs = SECTOR_SLUGS;
  sectorValue: SectorBaseModel;
  vendorTypeList: VendorType[] = [];
  filterInput: any = {
    mainFilters: ["Free Delivery", "Free Delivery"],
    sortByFilters: ["Alphabetical A-Z", "Rating"],
    cuisinesByFilters: [
      "Arabic",
      "American",
      "Asian",
      "Beverages",
      "Breakfast",
      "Breakfast",
      "Cuisines 1",
      "Cuisines 2",
      "Cuisines 3",
      "Cuisines 4",
    ],
  };
  constructor(
    private fb: FormBuilder,
    private attrService: AttributeService,
    private cuisineService: CuisineService,
    private sectorService: SectorService,
    private orderService: OrderService,
    private route :ActivatedRoute
  ) {
    this.orderTypeChanges();
  }

  ngOnInit(): void {
    this.sectorChanges()
    this.filterForm.get("selectedSector").valueChanges.subscribe((res) => {
      this.selectedSector=res
      this.sectorChanges()
    });
  
  }

  sectorChanges(){
    if (this.selectedSector) {
      this.sectorValue = this.sectorService.sectorValue(this.selectedSector);
      
      if (this.filterType == "sector") {
        this.getSectorChanges();
        this.getSortAttrs();
        this.addSortControlToForm();
        this.attributesInit();
        
        if (this.selectedSector == this.sectorSlugs.RESTAURANTS) {
          this.cuisinesInit();
        } else {
          if (this.selectedSector != this.sectorSlugs.GROCERIES) {
            console.log(this.selectedSector,"this.selectedSector===")
            // this.vendorTypesInit();
          }
          if (this.selectedSector != this.sectorSlugs.CHARITIES) {
            this.vendorTypesInit();
          }
        }
      }
    }
  }

  attributesInit() {
    this.attributesList = [];
    this.getAllAttrs();
  }
  cuisinesInit() {
    this.getAllCuisines();
    this.addCuisinesControlToForm();
    this.addSelectedCuisinesControl();
    this.getCuisinesChanges();
  }
  getSectorChanges() {
    this.filterForm.get("selectedSector")?.valueChanges.subscribe((res) => {
      console.log("filterType", this.filterType, res);
      this.sectorValue = this.sectorService.sectorValue(res);
      this.attributesInit();
      this.getSortAttrs();
    });
  }
  addSortControlToForm() {
    if (!this.filterForm.contains("sortAttr")) {
      this.filterForm.addControl("sortAttr", new FormControl("")); // Add a single FormControl for sorting
    }
  }

  getSortAttrs() {
    this.isSortAttrLoading = true;
    this.attrService
      .getSortAttributes()
      .pipe(takeUntil(this.destroy))
      .subscribe((res: SortAttributes[]) => {
        this.sortAttributesList = res;
        this.isSortAttrLoading = false;
      });
  }

  getAllAttrs() {
    this.isAttrLoading = true;
    this.attrService
      .getAttributes(this.sectorValue?.Code)
      .pipe(takeUntil(this.destroy))
      .subscribe((res: Attribute[]) => {
        this.attributesList = res;

        this.isAttrLoading = false;

        this.addSelectedAttributesControl();
      });
  }

  vendorTypesInit() {
    this.getAllVendortypes();
    this.addVendorTypesControl();
    this.addvendorTypesControlToForm();
    this.getVendorTypesChanges();
  }
  getAllVendortypes() {
    this.isVendorTypeLoading = true;
    this.attrService
      .getVendortypes(this.sectorValue?.Id)
      .pipe(takeUntil(this.destroy))
      .subscribe((res: VendorType[]) => {
        this.vendorTypeList = res;

        this.isVendorTypeLoading = false;

        this.populateVendorTypesFormArray();
      });
  }

  getVendorTypesChanges() {
    this.filterForm.get("vendorTypes")?.valueChanges.subscribe(() => {
      const selectedVendorTypes = this.getSelectedVendorTypes();
      this.filterForm.get("selectedVendorTypes")?.setValue(selectedVendorTypes); // Update selected cuisines in form
    });
  }
  getSelectedVendorTypes(): string[] {
    const vendorTypesFormArray = this.filterForm.get(
      "vendorTypes"
    ) as FormArray;

    // Loop through the FormArray controls
    const selectedCuisines = vendorTypesFormArray.controls
      .map((control, i) =>
        control.value ? this.vendorTypeList[i]?.Code : null
      ) // Map to cuisine code if selected
      .filter(Boolean); // Remove null or undefined values

    return selectedCuisines;
  }

  addvendorTypesControlToForm() {
    if (!this.filterForm.contains("vendorTypes")) {
      this.filterForm.addControl("vendorTypes", new FormArray([])); // FormArray for checkboxes
    }
  }

  populateVendorTypesFormArray() {
    const vendorTypesFormArray = this.filterForm.get(
      "vendorTypes"
    ) as FormArray;
    vendorTypesFormArray.clear();
    this.vendorTypeList.forEach(() => {
      vendorTypesFormArray.push(new FormControl(false));
    });
  }
  addVendorTypesControl() {
    if (!this.filterForm.contains("selectedVendorTypes")) {
      this.filterForm.addControl("selectedVendorTypes", new FormControl([])); // Control to store selected attributes
    }
  }

  addAttrControlToForm() {
    if (!this.filterForm.contains("attrs")) {
      this.filterForm.addControl("attrs", new FormArray([])); // FormArray for checkboxes
    }
  }

  addSelectedAttributesControl() {
    if (!this.filterForm.contains("selectedAttributes")) {
      this.filterForm.addControl("selectedAttributes", new FormControl("")); // Control to store selected attributes
    }
  }

  getCuisinesChanges() {
    this.filterForm.get("cuisines")?.valueChanges.subscribe(() => {
      const selectedCuisines = this.getSelectedCuisines();
      this.filterForm.get("selectedCuisines")?.setValue(selectedCuisines); // Update selected cuisines in form
    });
  }

  getSelectedCuisines(): string[] {
    const cuisinesFormArray = this.filterForm.get("cuisines") as FormArray;

    // Loop through the FormArray controls
    const selectedCuisines = cuisinesFormArray.controls
      .map((control, i) => (control.value ? this.cuisinesList[i]?.Slug : null)) // Map to cuisine code if selected
      .filter(Boolean); // Remove null or undefined values

    return selectedCuisines;
  }

  getAllCuisines() {
    this.isCuisinesLoading = true;
    this.cuisineService
      .getAllCuisine()
      .pipe(takeUntil(this.destroy))
      .subscribe((res: Cuisine[]) => {
        this.cuisinesList = res;
        this.populateCuisineFormArray();
        this.isCuisinesLoading = false;
      });
  }

  addCuisinesControlToForm() {
    if (!this.filterForm.contains("cuisines")) {
      this.filterForm.addControl("cuisines", new FormArray([])); // FormArray for checkboxes
    }
  }

  populateCuisineFormArray() {
    const cuisinesFormArray = this.filterForm.get("cuisines") as FormArray;
    cuisinesFormArray.clear();
    this.cuisinesList.forEach(() => {
      cuisinesFormArray.push(new FormControl(false));
    });
  }
  addSelectedCuisinesControl() {
    if (!this.filterForm.contains("selectedCuisines")) {
      this.filterForm.addControl("selectedCuisines", new FormControl([])); // Control to store selected attributes
    }
  }

  menuCategoryChecked(value: string) {
    this.filterForm?.get("menuCategory").setValue(value);
  }
  ngOnDestroy(): void {
    this.destroy.next(null);
    // this.destroy.complete();
  }

  orderTypeChanges() {
    this.orderService
      .getOrderTypeChanged()
      .pipe(takeUntil(this.destroy))
      .subscribe((res) => {
        if (this.filterType == "sector") {
          this.attributesInit();
        }
      });
  }
}
