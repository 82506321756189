<div>
    <div class="img-wrapper">
      <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': ' 16px', height: '168px' }"></ngx-skeleton-loader>
    </div>
    <div class="product-detail">
      <p> 
        <ngx-skeleton-loader
          [theme]="{ 'background-color': '#e8e8e8', width: '130px', 'border-radius': '15px',  height: '15px', 'margin-bottom': '6px'}">
        </ngx-skeleton-loader>
      </p>
      <p>
        <ngx-skeleton-loader
          [theme]="{ 'background-color': '#e8e8e8', width: '160px', 'border-radius': '15px',  height: '12px'}">
        </ngx-skeleton-loader>
      </p>
      <p>
        <ngx-skeleton-loader
          [theme]="{ 'background-color': '#e8e8e8', width: '160px', 'border-radius': '15px',  height: '12px'}">
        </ngx-skeleton-loader>
      </p>
      <!-- <h6>
        <ngx-skeleton-loader
          [theme]="{ 'background-color': '#e8e8e8', width: '70px', 'border-radius': '15px',  height: '14px'}">
        </ngx-skeleton-loader>
      </h6> -->
    </div>
</div>