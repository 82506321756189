<ng-template #template>
    <div class="modal-content custom-modal">
        <div class="modal-header border-bottom-0">
            <!-- <h1 class="modal-title fs-5 text-capitalize">{{menuItemOne?.Name}}</h1> -->
            <img src="assets/images/tm/icons/close_icon.png" class="ms-auto img-fluid close-img"
                (click)="this.modalRef?.hide()" alt="">
        </div>
        <div class="modal-body">
            <div class="container">
                <div class="row">
                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5">
                        <img src="{{menuItemOne?.ImageUrl}}" class="img-fluid rounded-5" alt="">

                        <label for="exampleFormControlInput1" class="form-label add-lbl-txt text-black">Add a special
                            note</label>
                        <textarea
                            class="form-control form-control-textarea-product mb-3 border-top-0 border-start-0 border-end-0"
                            placeholder="Description" rows="10" disabled></textarea>
                    </div>
                    <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-7">
                        <!-- <div class="card collapse-card  w-100">
                            <div class="card-header bg-white rounded-4 border-bottom-0" (click)="isCollapsed = !isCollapsed"
                            [attr.aria-expanded]="isCollapsed" aria-controls="collapseBasic">
                            Size (Required)
                            <img [src]="isCollapsed ? '../../../../assets/images/tm/icons/down_arrow.png':'../../../../assets/images/tm/icons/top_arrow.png'" class="collapse-arrow" alt="">
                            </div>
                            <div class="card-body pt-0" id="collapseBasic" [collapse]="!isCollapsed" [isAnimated]="true">
                                <hr class="mt-0 mb-1">
                                <div class="form-check mt-2">
                                    <input class="form-check-input radio-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked>
                                    <label class="form-check-label" for="flexRadioDefault1">
                                      Small
                                    </label>
                                  </div>
                                  <div class="form-check mt-2">
                                    <input class="form-check-input radio-check-input" type="radio" name="flexRadioDefault">
                                    <label class="form-check-label" for="flexRadioDefault2">
                                      Large
                                    </label>
                                  </div>
                            </div>
                        </div> -->
                        <h1 class="modal-title fs-5 text-capitalize">{{menuItemOne?.Name}}</h1>
                        <div class="row">
                            <p class="mt-3 lh-1 mb-2">{{menuItemOne?.Description}}</p>
                            <h2 class="price-txt">{{currencyFromat(menuItemOne?.Price?.Price)}} OMR</h2>
                        </div>


                        @if (menuItemOne?.HasCustomizations) {

                        @for (customization of menuItemOne?.Customizations; track $index) {



                        <div class="card collapse-card mt-3 w-100">
                            <div class="card-header bg-white rounded-4 border-bottom-0"
                                (click)="toggleCustomCollapse($index)"
                                [attr.aria-expanded]="!collapsedCustomizations[$index]"
                                [attr.aria-controls]="'collapseCustom-' + $index">
                                {{customization?.Name}}
                                <img [src]="isCustomCollapsed ? 'assets/images/tm/icons/down_arrow.png':'assets/images/tm/icons/top_arrow.png'"
                                    class="collapse-arrow" alt="">
                            </div>
                            <div class="card-body pt-0" id="collapseCustom"
                                [collapse]="!collapsedCustomizations[$index]" [isAnimated]="true">
                                <hr class="mt-0 mb-1">
                                @for (item of customization?.Items; track $index) {

                                <div class="form-check mt-2">
                                    <input class="form-check-input filter-check-input" type="checkbox"
                                        value="{{item?.Name}}" id="flexCheckDefault" disabled>
                                    <label class="form-check-label" for="flexCheckDefault">{{item?.Name}}</label>
                                    <span class="float-end text-bold">+ OMR <span
                                            class="fw-bold">{{currencyFromat(item?.Price)}}</span></span>
                                </div>

                                }

                                <!-- <div class="form-check mt-2">
                                            <input class="form-check-input filter-check-input" type="checkbox" value="Fries with extra cheese" id="flexCheckDefault" disabled>
                                            <label class="form-check-label" for="flexCheckDefault">Fries with extra cheese</label>
                                            <span  class="float-end">+ OMR 0.550</span>
                                        </div> -->
                            </div>
                        </div>
                        }



                        }

                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-top-0">
            <div class="container">
                <div class="row">
                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 d-flex justify-content-center">
                        <div class="w-50 item-cart d-flex justify-content-between align-items-center bg-white">
                            <img src="../../../../assets/images/tm/icons/clear_item.png" class="img-fluid" alt="">
                            <span class="qty-txt text-black">1</span>
                            <img src="../../../../assets/images/tm/icons/item_add.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-7">
                        <button
                            class="btn w-100 h-100 d-flex justify-content-between align-items-center add-cart-btn text-white"><span>3.000
                                OMR</span> <span class="text-capitalize fw-semibold">Add to order</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>