<div class="sector-card {{sectorData?.Slug==selectedSector? 'active' : ''}}">
    <div class="card border-0 mt-1 mx-1">
        <div class="d-flex justify-content-center">
            <img src="{{sectorData.ImageUrl}}" class="img-fluid sector-img">
        </div>
        @if (sectorData.Slug==selectedSector) {
            <span class="mt-3"></span>
        }
        <div></div>
    </div>
    <p class="text-center p-1 m-0 sector-title">{{sectorData.Name}}</p>
</div>