<!-- <header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>

  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <app-left-menu></app-left-menu>
            <div class="brand-logo">
              <a routerLink="/" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header> -->
<nav class="navbar navbar-expand-lg navbar-light bg-white border">
    <div class="container">
      <div class="d-flex align-items-center">
        <a class="me-3 hamburgerIcon" href="#">
            <img [src]="Hamburgermenu" alt="Hamburger Menu">
        </a>
        <a class="navbar-brand" href="#">
            <img [src]="themeLogo" alt="Logo">
        </a>
      </div>
        <div class="collapse navbar-collapse">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" href="/about-us">About Us</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" href="#">Blog</a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" href="/contact-us">Contact Us</a>
                </li>
            </ul>
        </div>
        <div class="d-flex">
            <button class="btn primary-btn">Sign Up or Login</button>
        </div>
    </div>
</nav>