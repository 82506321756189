export const SECTOR_SLUGS = {
    RESTAURANTS: 'restaurants',
    GROCERIES: 'groceries',
    STORES: 'stores',
    FLOWERS: 'flowers',
    CHARITIES: 'charities',
    TM_STORE: 'tm-store'
  };


  export const SECTOR_CODES = {
    FOOD: 'FOOD',
    SERVICE: 'service',
    GROC: 'GROC',
    FLOWER: 'FLOWER',
    CHARITY: 'CHARITY',
    MART: 'MART'
  };


  export const ORDER_TYPE={
    DELIVERY: 'DELIVERY',
    PICKUP: 'PICKUP'
  }