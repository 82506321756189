export const sectors :Sector[] = [
  { title: 'Restaurants', img: 'food-sector-icon.png', sector: 'restaurants' },
  { title: 'Groceries', img: 'grocery-sector-icon.png', sector: 'groceries' },
  { title: 'Flowers', img: 'food-sector-icon.png', sector: 'flowers' },
  { title: 'Electronics', img: 'grocery-sector-icon.png', sector: 'electronics' },
  { title: 'Donations', img: 'food-sector-icon.png', sector: 'donations' },
  { title: 'Others', img: 'grocery-sector-icon.png', sector: 'others' },
];

export interface Sector {
  title: string;
  img: string;
  sector: string;
}

export interface Store {
  storeName: string;
  items: string;
  img: string;
  Rate: number;
  RatedCount: number;
  deliveryTime: string;
  DeliveryFee: number;
  isSpOffer: boolean
}

export const stores: Store[] = [
  {storeName: 'Burger King', items: 'American, Burgers', img: 'Store-banner-2.png', Rate: 4.1, RatedCount: 245, deliveryTime: '20-30 Mins', DeliveryFee: 0.500, isSpOffer: true },
  {storeName: 'KFC - Muscat Expy', items: 'American, Burgers', img: 'Store-banner-1.png', Rate: 4.1, RatedCount: 245, deliveryTime: '20-30 Mins', DeliveryFee: 0.500, isSpOffer: true   },
  {storeName: 'Mr. Shawarma', items: 'American, Burgers', img: 'Store-banner-3.png', Rate: 4.1, RatedCount: 245, deliveryTime: '20-30 Mins', DeliveryFee: 0.500, isSpOffer: true   },
  {storeName: 'Burger King', items: 'American, Burgers', img: 'Store-banner-1.png', Rate: 4.1, RatedCount: 245, deliveryTime: '20-30 Mins', DeliveryFee: 0.500, isSpOffer: false   },
  {storeName: 'KFC - Muscat Expy', items: 'American, Burgers', img: 'Store-banner-3.png', Rate: 4.1, RatedCount: 245, deliveryTime: '20-30 Mins', DeliveryFee: 0.500, isSpOffer: true   },
  {storeName: 'Burger King', items: 'American, Burgers', img: 'Store-banner-2.png', Rate: 4.1, RatedCount: 245, deliveryTime: '20-30 Mins', DeliveryFee: 0.500, isSpOffer: false   },
  {storeName: 'KFC - Muscat Expy', items: 'American, Burgers', img: 'Store-banner-3.png', Rate: 4.1, RatedCount: 245, deliveryTime: '20-30 Mins', DeliveryFee: 0.500, isSpOffer: false   },
  {storeName: 'Burger King', items: 'American, Burgers', img: 'Store-banner-1.png', Rate: 4.1, RatedCount: 245, deliveryTime: '20-30 Mins', DeliveryFee: 0.500, isSpOffer: false   },
  {storeName: 'Mr. Shawarma', items: 'American, Burgers', img: 'Store-banner-2.png', Rate: 4.1, RatedCount: 245, deliveryTime: '20-30 Mins', DeliveryFee: 0.500, isSpOffer: false   },
]

export interface MenuItemData {
  itemName: string;
  description: string;
  itemImg: string;
  oldPrice: string;
  newPrice: string;
  qty: number
}

export const menuList: MenuItemData[] = [
  {itemName: 'Spicy Chicken Burger', description: '5 Pieces of Chicken Breast grille Lorem Ipsum is simply…', itemImg:'menu_item_1.png', oldPrice: '4.500', newPrice: '3.000', qty: 0},
  {itemName: 'Tandoori Chicken Biri…', description: '5 Pieces of Chicken Breast grille Lorem Ipsum is simply…', itemImg:'menu_item_2.png', oldPrice: '4.500', newPrice: '3.000', qty: 0},
  {itemName: 'Spicy Chicken Wrap', description: '5 Pieces of Chicken Breast grille Lorem Ipsum is simply…', itemImg:'menu_item_3.png', oldPrice: '4.500', newPrice: '3.000', qty: 1},
  {itemName: 'Chicken Biriyani', description: '5 Pieces of Chicken Breast grille Lorem Ipsum is simply…', itemImg:'menu_item_4.png', oldPrice: '4.500', newPrice: '3.000', qty: 2},
  {itemName: 'Item Name Here', description: '5 Pieces of Chicken Breast grille Lorem Ipsum is simply…', itemImg:'menu_item_5.png', oldPrice: '4.500', newPrice: '', qty: 0},
  {itemName: 'Item Name Here', description: '5 Pieces of Chicken Breast grille Lorem Ipsum is simply…', itemImg:'menu_item_8.png', oldPrice: '4.500', newPrice: '3.000', qty: 1},
  {itemName: 'Item Name Here', description: '5 Pieces of Chicken Breast grille Lorem Ipsum is simply…', itemImg:'menu_item_7.png', oldPrice: '4.500', newPrice: '3.000', qty: 0},
]



export const SECTOR_DATA = [
  {
    Id: "5e2b82a68cbfe31a8cd09c64",
    Code: "FOOD",
    Name: "Restaurants",
    ArabicName: "المطاعم",
    ImageUrl: "https://tmdone-cdn.s3.me-south-1.amazonaws.com/sectors/133679377331639454.png",
    ArabicImageUrl: "https://tmdone-cdn.s3.me-south-1.amazonaws.com/sectors/133679377331979461.png",
    Description: "Search for restaurants or items",
    DescriptionAR: "ابحث عن مطاعم او منتجات",
    IsVirtual: false,
    StoreId: null,
    Slug: "restaurants"
  },
  {
    Id: "5e3ad6b88cbfe41824acf7a8",
    Code: "service",
    Name: "Groceries",
    ArabicName: "البقالات",
    ImageUrl: "https://tmdone-cdn.s3.me-south-1.amazonaws.com/sectors/133679377783077419.png",
    ArabicImageUrl: "https://tmdone-cdn.s3.me-south-1.amazonaws.com/sectors/133679377783417413.png",
    Description: "Search for stores or items",
    DescriptionAR: "ابحث عن متاجر او منتجات",
    IsVirtual: false,
    StoreId: null,
    Slug: "groceries"
  },
  {
    Id: "5e2b82b38cbfe31a8cd09c68",
    Code: "GROC",
    Name: "Stores",
    ArabicName: "إلكترونيات",
    ImageUrl: "https://tmdone-cdn.s3.me-south-1.amazonaws.com/sectors/133679378173774312.png",
    ArabicImageUrl: "https://tmdone-cdn.s3.me-south-1.amazonaws.com/sectors/133679378174024288.png",
    Description: null,
    DescriptionAR: null,
    IsVirtual: false,
    StoreId: null,
    Slug: "stores"
  },
  {
    Id: "6066f73d8cbfe92ad8b72422",
    Code: "FLOWER",
    Name: "Flowers",
    ArabicName: "زهور",
    ImageUrl: "https://tmdone-cdn.s3.me-south-1.amazonaws.com/sectors/133679378459179359.png",
    ArabicImageUrl: "https://tmdone-cdn.s3.me-south-1.amazonaws.com/sectors/133679378460039431.png",
    Description: "Search for flowers",
    DescriptionAR: null,
    IsVirtual: false,
    StoreId: null,
    Slug: "flowers"
  },
  {
    Id: "6165a3ae8cbfe339bc9aeff3",
    Code: "CHARITY",
    Name: "Charities",
    ArabicName: "الجمعيات الخيرية",
    ImageUrl: "https://tmdone-cdn.s3.me-south-1.amazonaws.com/sectors/133679377964080841.png",
    ArabicImageUrl: "https://tmdone-cdn.s3.me-south-1.amazonaws.com/sectors/133679377964340593.png",
    Description: "Search an organization",
    DescriptionAR: "ابحث عن",
    IsVirtual: false,
    StoreId: null,
    Slug: "charities"
  },
  {
    Id: "66e14f6ca888895748d3a956",
    Code: "MART",
    Name: "TM Store",
    ArabicName: "متجر تم دن",
    ImageUrl: "https://tmdone-cdn.s3.me-south-1.amazonaws.com/sectors/DepartmentStore.png",
    ArabicImageUrl: "https://tmdone-cdn.s3.me-south-1.amazonaws.com/sectors/DepartmentStore.png",
    Description: null,
    DescriptionAR: null,
    IsVirtual: false,
    StoreId: null,
    Slug: "tm-store"
  }
];
