import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductSharedService {

  constructor() { }

  private subjectOpenProductModal = new BehaviorSubject(null);

  setSubjectOpenProductModal(store: any) {
    this.subjectOpenProductModal.next(store);
  }

  getSubjectOpenProductModal(): Observable<any> {
    return this.subjectOpenProductModal.asObservable();
  }
}
