<div class="item-container">
    <div class="item-img-div position-relative">
        <!-- <div class="position-absolute item-cart d-flex justify-content-between align-items-center bg-white d-none" [class]="menuItem?.qty > 0 ? 'item-cart-qty':''">
            @if(productData?.qty > 0) {
                <img src="assets/images/tm/icons/clear_item.png" class="img-fluid" alt="">
                <span class="qty-txt text-black">{{productData.qty}}</span>
            }
            <img src="assets/images/tm/icons/item_add.png" class="img-fluid" alt="">
        </div> -->
        <img src="{{menuItem.ImageUrl}}" class="position-relative overlay-image" alt="">
    </div>
    <div class="item-info-div px-2 mt-2">
        <h2 class="item-name letter-space text-capitalize">{{menuItem?.Name}}</h2>
        <p class="item-note">{{menuItem.Description}}</p>
        <div class="d-flex justify-content-between">
            @if(menuItem.Price.IsItemOnSale) {
                <span class="new-price">{{currencyFromat(menuItem.Price.SalePrice)}} OMR</span>
                <span class="old-price"><del class="old-price">{{currencyFromat(menuItem.Price.Price)}} OMR</del></span>
            }@else {
                <span class="old-price text-black"><b>{{currencyFromat(menuItem.Price.Price)}} OMR</b></span>
            }
        </div>
    </div>
</div>
