import { Component,Input } from '@angular/core';
import { MenuItem } from 'src/app/home/sectors/stores/models/menu-items';

@Component({
  selector: 'app-product-tile-one',
  templateUrl: './product-tile-one.component.html',
  styleUrl: './product-tile-one.component.scss'
})
export class ProductTileOneComponent {
@Input() menuItem: MenuItem;
currencyFromat(price: number) {
  return price?.toFixed(3);
}
}
