import { Component,Input } from '@angular/core';

import { Location, LocationSearchResult } from 'src/app/shared/data/location-search';

@Component({
  selector: 'app-location-search-item',
  standalone: true,
  imports: [],
  templateUrl: './location-search-item.component.html',
  styleUrl: './location-search-item.component.scss'
})
export class LocationSearchItemComponent {
  @Input() location!: Location;
  constructor() {
    
  }


}
