<div class="conatiner px-3 pt-4 filter-container">

    @if (this.filterType=='sector') {


    <div class="row d-flex justify-content-end ">
        <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
            <h4>All {{ sectorValue?.Name}}</h4>
          
        </div>
    </div>
    <div class="row d-flex justify-content-end ">
        <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">

                <button type="button" class="btn w-100 rest-btn text-capitalize">Reset</button>
       
        </div>
    </div>
    
    @if(!isAttrLoading) {
    <div [formGroup]="filterForm" class="mt-3">
        <div class="row mt-2 d-flex justify-content-end" >
            @for(attr of attributesList; track $index) {
            <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
                <div class="form-check mb-2">
                    <input class="form-check-input filter-check-input" type="radio" [value]="attr?.Code"
                        id="flexCheckDefault" [formControlName]="'selectedAttributes'">
                    <label class="form-check-label" for="flexCheckDefault">
                        {{attr?.Name}}
                    </label>
                </div>
            </div>
            }
        </div>
    </div>
    }@else{
        <div class="row mt-2 d-flex justify-content-end mt-3">
            <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
    
            </div>
        </div>
        <app-skeleton-filter-checks [skeletonItems]="[1,2,3,4,5,6]"></app-skeleton-filter-checks>
    }

    @if(!isSortAttrLoading) {

    <div class="row mt-2 d-flex justify-content-end mt-3">
        <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
            <h5>Sort By</h5>
        </div>
    </div>

    <div class="row mt-2" [formGroup]="filterForm">
        <div>

            @for(sortFilter of sortAttributesList; track $index) {
            <div class="row d-flex justify-content-end">

                <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12 ">
                    <div class="form-check mb-2">
                        <!-- Bind the checkbox value to the FormArray using FormControl -->
                        <input class="form-check-input filter-check-input" type="radio" formControlName="sortAttr"
                            id="sort-{{ $index }}" [value]="sortFilter.Code" />
                        <label class="form-check-label" for="sort-{{ $index}}">
                            {{ sortFilter.Name }}
                        </label>
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
    }@else {
    <div class="row mt-2 d-flex justify-content-end mt-3">
        <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
            <h5>Sort By</h5>
        </div>
    </div>
    <app-skeleton-filter-checks [skeletonItems]="[1,2,3,4,5,6]"></app-skeleton-filter-checks>
    }


    @if (selectedSector==sectorSlugs.RESTAURANTS) {
    @if(!isCuisinesLoading) {
    <div class="row mt-2 d-flex justify-content-end mt-3">
        <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
            <h5>Cuisines</h5>
        </div>
    </div>
    <div [formGroup]="filterForm">
        <div class="row mt-2 d-flex justify-content-end" formArrayName="cuisines">
            @for(cuisine of cuisinesList; track $index) {
            <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
                <div class="form-check mb-2">
                    <input class="form-check-input filter-check-input" type="checkbox" id="flexCheckDefault"
                        [formControlName]="$index">
                    <label class="form-check-label" for="flexCheckDefault">
                        {{cuisine.Name}}
                    </label>
                </div>
            </div>
            }
        </div>
    </div>
    }@else {
    <div class="row mt-2 d-flex justify-content-end mt-3">
        <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
            <h5>Cuisines</h5>
        </div>
    </div>
    <app-skeleton-filter-checks [skeletonItems]="[1,2,3,4,5,6]"></app-skeleton-filter-checks>}

    }


    @if (selectedSector!=sectorSlugs.RESTAURANTS && this.selectedSector != this.sectorSlugs.CHARITIES) {
    @if(!isVendorTypeLoading) {
    <div class="row mt-2 d-flex justify-content-end mt-3">
        <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
            <h5>Store Types</h5>
        </div>
    </div>
    <div [formGroup]="filterForm">
        <div class="row mt-2 d-flex justify-content-end" formArrayName="vendorTypes">
            @for(vendorType of vendorTypeList; track $index) {
            <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
                <div class="form-check mb-2">
                    <input class="form-check-input filter-check-input" type="checkbox" id="flexCheckDefault"
                        [formControlName]="$index">
                    <label class="form-check-label" for="flexCheckDefault">
                        {{vendorType?.Name}}
                    </label>
                </div>
            </div>
            }
        </div>
    </div>
    }@else {
    <div class="row mt-2 d-flex justify-content-end mt-3">
        <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
            <h5>Store Types</h5>
        </div>
    </div>
    <app-skeleton-filter-checks [skeletonItems]="[1,2,3,4,5,6]"></app-skeleton-filter-checks>
}

    }





    }@else { 

        <!-- store  -->
   
        @if(menuCategories?.length>0 ){
        <div class="row d-flex justify-content-end ">
        <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
            <h4>{{selectedSector==sectorSlugs.RESTAURANTS ? 'Menu Items' : 'Menu Categories'}}</h4>
            <div class="px-4">

                <!-- <button type="button" class="btn w-100 rest-btn text-capitalize">Reset</button> -->
            </div>

            
                    <div>
                        <div class="row mt-5 mb-5" [formGroup]="filterForm">
                        @for(menuCategory of menuCategories; track $index) {
                   
                            <div class="row d-flex menuCategory">
            
                            <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12 ">
                                <div class="form-check mb-2 menuCategory-el {{filterForm?.get('menuCategory').value==menuCategory.Code ? 'active' : ''}}" (click)="menuCategoryChecked(menuCategory.Code)">
                                    <!-- Bind the checkbox value to the FormArray using FormControl -->
                                    <!-- <input class="form-check-input filter-check-input" type="radio" formControlName="menuCategory"
                                        id="menuCategory{{ $index }}" [value]="menuCategory.Code"/> -->
                                    <label class="form-check-label" for="menuCategory{{ $index}}">
                                        {{ menuCategory.Name }} 
                                    </label>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                   
                   
               
        </div>
    </div>
    }@else{
              
    <app-skeleton-filter-checks [skeletonItems]="[1,2,3,4,5,6]"></app-skeleton-filter-checks>

    }

    }

</div>