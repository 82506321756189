<!--footer section -->
<!-- <div class="mt-5"></div> -->

<footer [ngClass]="class">
    <div class="w-1000 footer-stripe-1"></div>
    <div class="w-1000 footer-stripe-2"></div>
    <div class="w-1000 footer-stripe-3"></div>
    <div *ngIf="mainFooter" class="tm-footer">
        <div class="container">
            <section class="section-b-space border-b">
                <div class="row">

                    <div class="col-md-6">
                        <div class="row footer-base-det mt-3">
                            <div class="position-absolute position-logo">
                                <img src="assets/images/tm/footer-logo.png" alt="">
                            </div>
                            <div class="col-md-4">
                              
                                <h6>TM DONE </h6>
                                <h5>Ghala Street, Muscat, Oman, 116</h5>
                            </div>
                            <div class="col-md-4">
                                <h6>Mobile </h6>
                                <h5>+968 24 399953</h5>
                            </div>
                            <div class="col-md-4">
                                <h6>Email </h6>
                                <h5>{{'info@tmdone.com'}}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="payment-card-bottom">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)">
                                        <img src="assets/images/icon/visa.png" alt="">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <img src="assets/images/icon/mastercard.png" alt="">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <img src="assets/images/icon/paypal.png" alt="">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <img src="assets/images/icon/american-express.png" alt="">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <img src="assets/images/icon/discover.png" alt="">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </div>

    <div class="sub-footer  tm-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end policy-links ">
                        <ul>
                            <li class="me-3"><p><a class="txt-primary" href="">Privacy Policy </a></p></li>
                            <li class="me-3"><p><a class="txt-primary" href="">Terms and Conditions</a></p></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <p class="text-md-end text-center"><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y' }}
                        Copyright, All Rights Reserved TM!DONE</p>

                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->